import React, { Component } from 'react'
import './Dashboard.sass'

const bi = "https://bi.services.builderall.com/public/dashboard/1d929c8a-e0c6-4dfb-8372-61eb31abd2b6#bordered=false&titled=false"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 1024
    }
  }

  render() {
    return (
      <div className="Dashboard">
        <iframe src={bi} title="BI" />
      </div>
    )
  }
}
