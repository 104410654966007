import React, { Component } from 'react'
import Axios from 'axios'
import moment from 'moment'
import { MD5 } from 'crypto-js'
import Pagination from '../common/Pagination'
import env from '../../env'
import Empty from '../common/Empty'

const PAGINATION_LIMIT = 12

export default class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      page: 0,
      count: 0,
      error: '',
      current: null,
      loadingAccess: false,
      loadingList: false,
      loadingLock: false,
      filter: {
        search: ''
      }
    }
  }

  async componentDidMount() {
    await this.load()
  }

  async load(page = 1, filter = {}) {
    try {
      this.setState({ loadingList: true, error: null })
      filter = { ...this.state.filter, ...filter }
      const { data: { list, count } } = await Axios.get(`/users/list?page=${page}&limit=${PAGINATION_LIMIT}&search=${filter.search || ''}`)
      this.setState({ loadingList: false, page, list, count, filter })
    } catch (error) {
      this.setState({ loadingList: false, error })
    }
  }

  change(field) {
    return event => {
      let { filter } = this.state
      filter[field] = event.target.value
      this.setState({ filter })
    }
  }

  toggle(current) {
    return () => this.setState({ current })
  }

  async access() {
    try {
      this.setState({ error: null, loadingAccess: true })
      const { current } = this.state

      const { data: { token } } = await Axios.get(`/users/login?id=${current.ID}`)
      this.setState({ loadingAccess: false })

      window.open(`${env.client}/#/login?token=${token}`, '_blank')
    } catch (error) {
      this.setState({ error, loadingAccess: false })
    }
  }

  async lock() {
    try {
      this.setState({ error: null, loadingLock: true })
      const { current, list } = this.state

      const { data: user } = await Axios.patch('/users/update', {
        ...current, Blocked: !current.Blocked
      })

      this.setState({
        loadingLock: false,
        list: list.map(u => u.ID === current.ID ? user : u),
        current: user
      })
    } catch (error) {
      this.setState({ error, loadingLock: false })
    }
  }

  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=200`
    return url
  }

  render() {
    const { list, count, page, current, filter, loadingList, loadingLock, loadingAccess, error } = this.state

    const modal = () => (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.toggle()}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Usuário #{current.ID}</p>
            <button className="delete" aria-label="close" onClick={this.toggle()}></button>
          </header>
          <section className="modal-card-body">
            <div className="notification is-danger" hidden={!error}>{error}</div>
            <div className="columns is-multiline">
              <div className="column is-one-third">
                <figure className="image">
                  <img src={this.gravatar(current.Email)} alt={current.Name} />
                </figure>
              </div>
              <div className="column">
                <b>Nome</b><br />
                {current.Name}
                <br /><br />
                <b>Email</b><br />
                {current.Email}
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className={`button is-info ${loadingAccess && 'is-loading'}`} onClick={this.access.bind(this)}>
              Acessar carteira &nbsp;
              <i className="fas fa-external-link-alt"></i>
            </button>
            <button className={`button is-danger ${loadingLock && 'is-loading'}`} onClick={this.lock.bind(this)}>
              <span hidden={current.Blocked}>
                Bloquear &nbsp;
                <i className="fas fa-lock"></i>
              </span>
              <span hidden={!current.Blocked}>
                Desbloquear &nbsp;
                <i className="fas fa-lock-open"></i>
              </span>
            </button>
          </footer>
        </div>
      </div>
    )

    return (
      <div className="UserAdmin">
        <div className="columns">
          <div className="column">
            <span className="title is-4 is-marginless">Usuários</span>
          </div>
          <div className="column is-two-quarters-tablet is-one-third-widescreen">
            <div className="columns is-mobile">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input className="input" type="text" placeholder="Buscar por id, nome ou email" value={filter.search} onChange={this.change('search')} />
                  </div>
                </div>
              </div>
              <div className="column is-one-fifth">
                <button className={`button is-primary is-fullwidth ${loadingList && 'is-loading'}`} onClick={() => this.load(1, filter)}>
                  <span className="icon is-small">
                    <i className="fas fa-search"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="content is-relative">
          <div className="notification is-danger" hidden={!error}>{error}</div>
          <table className="table is-hoverable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th className="is-hidden-mobile">Email</th>
                <th className="is-hidden-mobile">Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map(u => (
                <tr key={u.ID}>
                  <td>{u.ID}</td>
                  <td>
                    {u.Name}
                    <span className="is-hidden-tablet is-size-7"><br />{u.Email}</span>
                  </td>
                  <td className="is-hidden-mobile">{u.Email}</td>
                  <td className="is-hidden-mobile">{moment(u.CreatedAt).format('LLL')}</td>
                  <td className="has-text-right">
                    <span hidden={!u.Blocked}>
                      <i className="fas fa-lock has-text-grey" title="Usuário bloqueado"></i> &nbsp;&nbsp;&nbsp;
                    </span>
                    <button className="button is-small" onClick={this.toggle(u)}>Ver</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Empty hidden={list.length > 0} />
        </div>
        <Pagination count={count} step={PAGINATION_LIMIT} current={page} onChange={this.load.bind(this)} />
        {current && modal()}
      </div>
    )
  }
}
