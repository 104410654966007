import React, { Component } from 'react'
import './Login.sass'
import Axios from 'axios'
import { parse } from 'query-string'

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        Email: '',
        Password: '',
        isValid: false
      },
      error: '',
      loading: false,
      redir: ''
    }
  }

  componentDidMount() {
    let { redir } = parse(this.props.location.search)
    delete Axios.defaults.headers.Authorization
    this.setState({ redir })

  }

  change(field) {
    return event => {
      let { form } = this.state
      form[field] = event.target.value
      form.isValid = this.refs.form.checkValidity()
      this.setState({ form })
    }
  }

  async login() {
    try {
      this.setState({ error: '', loading: true })

      const { form, redir } = this.state
      const { data: { token } } = await Axios.post('/admins/login', form)

      Axios.defaults.headers.Authorization = `Bearer ${token}`
      window.open(`#${redir || '/panel/dashboard'}`, '_self')
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { form, loading, error } = this.state

    return (
      <div className="Login has-background-light">
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered is-vcentered">
                <div className="column is-one-quarter-widescreen has-text-centered has-text-right-tablet">
                  <h1 className="title">Carteira Digital</h1>
                  <h2 className="subtitle">Administração</h2>
                </div>
                <div className="column is-one-quarter-widescreen">
                  <div className="notification is-danger box" hidden={!error}>{error}</div>
                  <form className="box" ref="form" noValidate>
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input className="input" type="email" autoComplete="username" placeholder="Digite seu email" value={form.Email} onChange={this.change('Email')} required />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Senha</label>
                      <div className="control">
                        <input className="input" type="password" autoComplete="current-password" placeholder="Digite sua senha" value={form.Password} onChange={this.change('Password')} required />
                      </div>
                    </div>
                    <p className="has-text-right">
                      <button className={`button is-primary ${loading && 'is-loading'}`} type="button" onClick={this.login.bind(this)} disabled={!form.isValid}>Entrar</button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
