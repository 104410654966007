import React, { Component } from 'react'
import Axios from 'axios'
import moment from 'moment'
import Pagination from '../common/Pagination'
import Empty from '../common/Empty'

const PAGINATION_LIMIT = 12

export default class UserAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      page: 0,
      count: 0,
      error: '',
      current: null,
      loadingAccess: false,
      loadingList: false,
      filter: {
        search: ''
      }
    }
  }

  async componentDidMount() {
    await this.load()
  }

  async load(page = 1, filter = {}) {
    try {
      this.setState({ loadingList: true, error: null })
      filter = { ...this.state.filter, ...filter }
      const { data: { list, count } } = await Axios.get(`/admins?page=${page}&limit=${PAGINATION_LIMIT}&search=${filter.search || ''}`)
      this.setState({ loadingList: false, page, list, count, filter })
    } catch (error) {
      this.setState({ loadingList: false, error })
    }
  }

  change(field) {
    return event => {
      let { filter } = this.state
      filter[field] = event.target.value
      this.setState({ filter })
    }
  }

  render() {
    const { list, count, page, filter, loadingList, error } = this.state

    return (
      <div className="UserAdmin">
        <div className="columns">
          <div className="column">
            <span className="title is-4 is-marginless">Administradores</span>
          </div>
          <div className="column is-two-quarters-tablet is-one-third-widescreen">
            <div className="columns is-mobile">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input className="input" type="text" placeholder="Buscar por id, nome ou email" value={filter.search} onChange={this.change('search')} />
                  </div>
                </div>
              </div>
              <div className="column is-one-fifth">
                <button className={`button is-primary is-fullwidth ${loadingList && 'is-loading'}`} onClick={() => this.load(1, filter)}>
                  <span className="icon is-small">
                    <i className="fas fa-search"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="content is-relative">
          <div className="notification is-danger" hidden={!error}>{error}</div>
          <table className="table is-hoverable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th className="is-hidden-mobile">Email</th>
                <th className="is-hidden-touch">Data</th>
              </tr>
            </thead>
            <tbody>
              {list.map(u => (
                <tr key={u.ID}>
                  <td>{u.ID}</td>
                  <td>
                    {u.Name}
                    <span className="is-hidden-tablet is-size-7"><br />{u.Email}</span>
                  </td>
                  <td className="is-hidden-mobile">{u.Email}</td>
                  <td className="is-hidden-touch">{moment(u.CreatedAt).format('LLL')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Empty hidden={list.length > 0} />
        </div>
        <Pagination count={count} step={PAGINATION_LIMIT} current={page} onChange={this.load.bind(this)} />
      </div>
    )
  }
}
