import React, { Component } from 'react'
import img from '../../assets/no-results.svg'

export default class Empty extends Component {
  render() {
    const { hidden } = this.props
    return (
      <div className="Empty has-text-centered" hidden={hidden}>
        <figure className="image is-128x128 is-inline-block is-marginless">
          <img src={img} alt="Nenhum resultado" />
        </figure>
        <br />
        <b className="has-text-grey-lighter">Nenhum resultado</b>
      </div>
    )
  }
}
