import React, { Component } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/Login'
import Withdrawal from './pages/Withdrawal'
import UserAdmin from './pages/UserAdmin'
import Navbar from './common/Navbar'
import Dashboard from './pages/Dashboard'
import User from './pages/User'

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/panel/*">
              <Navbar />
              <section className="section">
                <Switch>
                  <Route path="/panel/dashboard" component={Dashboard} />
                  <Route path="/panel/user-admin" component={UserAdmin} />
                  <Route path="/panel/user" component={User} />
                  <Route path="/panel/withdrawal" component={Withdrawal} />
                </Switch>
              </section>
            </Route>
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>
        </HashRouter>
      </div>
    )
  }
}

export default App
