import React, { Component } from 'react'

export default class Pagination extends Component {
  paginate(c, m) {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i)
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i;
    }

    return rangeWithDots
  }

  render() {
    const { count, step, current, onChange } = this.props
    const pages = Math.ceil(count / step)

    const links = this.paginate(current, pages)

    return (
      <div className="Pagination">
        <nav className="pagination is-right is-small" role="navigation" hidden={pages < 2}>
          <span className="pagination-previous" onClick={() => onChange(current - 1)} hidden={current - 1 <= 0}>Anterior</span>
          <span className="pagination-next" onClick={() => onChange(current + 1)} hidden={current === pages}>Próximo</span>
          <ul className="pagination-list">
            {links.map((l, i) => (
              <li key={i}>
                <span className="pagination-ellipsis" hidden={l !== '...'}>&hellip;</span>
                <span className={`pagination-link ${current === l && 'is-current'}`} onClick={() => onChange(l)} hidden={l === '...'}>{l}</span>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    )
  }
}
