import React, { Component } from 'react'
import Axios from 'axios'
import moment from 'moment'
import { stringify } from 'query-string'
import Pagination from '../common/Pagination'
import env from '../../env'
import Empty from '../common/Empty'

const PAGINATION_LIMIT = 12
const WITHDRAWAL_FEE = 5 / 100

const ACCOUNT_TYPES = {
  'Conta corrente': 'C/C',
  'Conta poupança': 'C/P',
  'Conta salário': 'C/S'
}

export default class Withdrawal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      page: 0,
      count: 0,
      error: '',
      current: null,
      loadingPay: false,
      loadingAccess: false,
      loadingList: false,
      loadingExport: false,
      banks: [],
      filter: {
        status: 'pending',
        search: '',
        bank: 0
      }
    }
  }

  async componentDidMount() {
    await this.load(1, {}, true)
  }

  async load(page = 1, filter = {}, banks = false) {
    try {
      this.setState({ loadingList: true, error: null })

      filter = { ...this.state.filter, ...filter }
      const query = stringify({ ...filter, page, limit: PAGINATION_LIMIT })

      const { data: { list, count } } = await Axios.get(`/withdrawals?${query}`)

      if (banks) await this.loadBanks()
      this.setState({ loadingList: false, page, list, count, filter })
    } catch (error) {
      this.setState({ loadingList: false, error })
    }
  }

  async export(filter = {}) {
    try {
      this.setState({ loadingExport: true, error: null })

      filter = { ...this.state.filter, ...filter }
      const query = stringify({ ...filter, export: true })

      const { data: { file } } = await Axios.get(`/withdrawals?${query}`)

      window.open(`${env.api}/storage/${file}`, '_self')

      this.setState({ loadingExport: false })
    } catch (error) {
      this.setState({ loadingExport: false, error })
    }
  }

  toggle(current) {
    return () => this.setState({ current })
  }

  async pay() {
    try {
      this.setState({ error: null, loadingPay: true })
      const { current, page } = this.state

      await Axios.patch(`/withdrawals/${current.ID}/pay`)

      await this.load(page)
      this.setState({ loadingPay: false, current: null })
    } catch (error) {
      this.setState({ error, loadingPay: false })
    }
  }

  async access() {
    try {
      this.setState({ error: null, loadingAccess: true })
      const { current } = this.state

      const { data: { token } } = await Axios.get(`/users/login?id=${current.Transaction.Sender.ID}`)
      this.setState({ loadingAccess: false })

      window.open(`${env.client}/#/login?token=${token}`, '_blank')
    } catch (error) {
      this.setState({ error, loadingAccess: false })
    }
  }

  async loadBanks() {
    try {
      const { data: banks } = await Axios.get('/banks')
      this.setState({ banks })
    } catch (error) {
      this.setState({ error })
    }
  }

  change(field) {
    return event => {
      let { filter } = this.state
      filter[field] = event.target.value
      this.setState({ filter })
    }
  }

  render() {
    const { list, count, page, current, filter, loadingList, loadingPay, loadingAccess, loadingExport, banks, error } = this.state

    const modal = () => (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.toggle()}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Saque #{current.ID}</p>
            <button className="delete" aria-label="close" onClick={this.toggle()}></button>
          </header>
          <section className="modal-card-body">
            <div className="notification is-danger" hidden={!error}>{error}</div>
            <div className="columns is-multiline is-mobile">
              <div className="column is-half">
                <b>ID da transação</b><br />
                {current.Transaction.ID}
              </div>
              <div className="column is-half">
                <b>ID do usuário</b><br />
                {current.Transaction.Sender.ID}
              </div>
              <div className="column is-half">
                <b>Email do usuário</b><br />
                <div className="has-text-ellipsis">{current.Transaction.Sender.Email}</div>
              </div>
              <div className="column is-half">
                <b>Data</b><br />
                <span className="is-hidden-mobile">{moment(current.Transaction.CreatedAt).format('LLL')}</span>
                <span className="is-hidden-tablet">{moment(current.Transaction.CreatedAt).format('LL')}</span>
              </div>
              <div className="column is-half">
                <b>Nome do titular</b><br />
                {current.Option.Name}
              </div>
              <div className="column is-half">
                <b>CPF do titular</b><br />
                {current.Option.CPF}
              </div>
              <div className="column is-half">
                <b>Banco</b><br />
                {current.Option.Bank.Code} - {current.Option.Bank.Name}
              </div>
              <div className="column is-half">
                <b>Agência</b><br />
                {current.Option.Agency}{current.Option.AgencyDigit ? `-${current.Option.AgencyDigit}` : ''}
              </div>
              <div className="column is-half">
                <b>Conta</b><br />
                {current.Option.Account}{current.Option.AccountDigit ? `-${current.Option.AccountDigit}` : ''}
              </div>
              <div className="column is-half">
                <b>Tipo de conta</b><br />
                {current.Option.AccountType}
              </div>
              <div className="column is-full">
                <hr className="is-marginless" />
              </div>
              <div className="column is-one-third">
                <b>Valor bruto</b><br />
                R$ {current.Transaction.Amount.toFixed(2)}
              </div>
              <div className="column is-one-third">
                <b>Taxa</b><br />
                R$ {(current.Transaction.Amount * WITHDRAWAL_FEE).toFixed(2)}
              </div>
              <div className="column is-one-third">
                <b>Valor líquido</b><br />
                R$ {(current.Transaction.Amount * (1 - WITHDRAWAL_FEE)).toFixed(2)}
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className={`button is-success ${loadingPay && 'is-loading'}`} hidden={current.Transaction.Status !== 'pending'} onClick={this.pay.bind(this)}>Confirmar pagamento</button>
            <button className={`button is-info ${loadingAccess && 'is-loading'}`} onClick={this.access.bind(this)}>
              Acessar carteira &nbsp;
              <i className="fas fa-external-link-alt"></i>
            </button>
          </footer>
        </div>
      </div>
    )

    return (
      <div className="Withdrawal">
        <div className="columns">
          <div className="column">
            <span className="title is-4 is-marginless">Saques</span>
            <button className={`button is-primary is-pulled-right ${loadingExport ? 'is-loading' : 'is-inverted'}`} title="Exportar para planilha Excel" onClick={() => this.export(filter)}>
              <i className="fas fa-file-download"></i>
            </button>
          </div>
          <div className="column is-three-quarters-tablet is-half-widescreen">
            <div className="columns is-mobile is-multiline">
              <div className="column is-full-mobile">
                <div className="field">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select value={filter.bank} onChange={this.change('bank')}>
                        <option value={0}>Todos os bancos</option>
                        {banks.map(b => (
                          <option value={b.ID} key={b.ID}>{b.Code} - {b.Name}</option>
                        ))}
                        <option value={-1}>Outros bancos</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input className="input" type="text" placeholder="Buscar por id, nome, email ou CPF" value={filter.search} onChange={this.change('search')} />
                  </div>
                </div>
              </div>
              <div className="column is-one-fifth">
                <button className={`button is-primary is-fullwidth ${loadingList && 'is-loading'}`} onClick={() => this.load(1, filter)}>
                  <span className="icon is-small">
                    <i className="fas fa-search"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs is-right is-boxed">
          <ul>
            <li className={`${filter.status === 'pending' && 'is-active'}`} onClick={() => this.load(1, { status: 'pending' })}>
              <a>Pendentes</a>
            </li>
            <li className={`${filter.status === 'completed' && 'is-active'}`} onClick={() => this.load(1, { status: 'completed' })}>
              <a>Concluídos</a>
            </li>
          </ul>
        </div>
        <div className="content is-relative">
          <div className="notification is-danger" hidden={!error}>{error}</div>
          <table className="table is-hoverable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome do titular</th>
                <th className="is-hidden-touch is-hidden-desktop-only">Banco</th>
                <th className="is-hidden-touch is-hidden-desktop-only">Agência</th>
                <th className="is-hidden-touch is-hidden-desktop-only">Conta</th>
                <th className="is-hidden-mobile">Valor bruto</th>
                <th className="is-hidden-touch">Taxa</th>
                <th className="is-hidden-touch">Valor líquido</th>
                <th className="is-hidden-mobile is-hidden-widescreen-only">Solicitado</th>
                <th className="is-hidden-mobile is-hidden-desktop-only is-hidden-widescreen-only" hidden={filter.status !== 'completed'}>Pago</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map(w => (
                <tr key={w.ID}>
                  <td>{w.ID}</td>
                  <td>
                    {w.Option.Name}
                    <span className="is-hidden-tablet is-size-7"><br />{w.Option.CPF}</span>
                  </td>
                  <td className="is-hidden-touch is-hidden-desktop-only">{w.Option.Bank.Code} - {w.Option.Bank.Name}</td>
                  <td className="is-hidden-touch is-hidden-desktop-only">{w.Option.Agency}{w.Option.AgencyDigit ? `-${w.Option.AgencyDigit}` : ''}</td>
                  <td className="is-hidden-touch is-hidden-desktop-only">
                    <span title={w.Option.AccountType}>{ACCOUNT_TYPES[w.Option.AccountType]}</span>&nbsp;
                    {w.Option.Account}{w.Option.AccountDigit ? `-${w.Option.AccountDigit}` : ''}
                  </td>
                  <td className="is-hidden-mobile">R$ {w.Transaction.Amount.toFixed(2)}</td>
                  <td className="is-hidden-touch">R$ {(w.Transaction.Amount * WITHDRAWAL_FEE).toFixed(2)}</td>
                  <td className="is-hidden-touch">R$ {(w.Transaction.Amount * (1 - WITHDRAWAL_FEE)).toFixed(2)}</td>
                  <td className="is-hidden-mobile is-hidden-widescreen-only">
                    {moment(w.Transaction.CreatedAt).format('LL')}
                  </td>
                  <td className="is-hidden-mobile is-hidden-desktop-only is-hidden-widescreen-only" hidden={filter.status !== 'completed'}>
                    {moment(w.Transaction.UpdatedAt).format('LL')}
                  </td>
                  <td className="has-text-right">
                    <button className={`button is-small ${w.Transaction.Status === 'pending' ? 'is-success' : 'is-info'}`} onClick={this.toggle(w)}>
                      {w.Transaction.Status === 'pending' ? 'Pagar' : 'Ver'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Empty hidden={list.length > 0} />
        </div>
        <Pagination count={count} step={PAGINATION_LIMIT} current={page} onChange={this.load.bind(this)} />
        {current && modal()}
      </div>
    )
  }
}
