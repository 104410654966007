const environments = {
  production: {
    api: 'https://app.carteiradigital.net',
    client: 'https://app.carteiradigital.net/web'
  },
  test: {
    api: 'https://wallet.builderall.io',
    client: 'https://wallet.builderall.io/web'
  },
  development: {
    api: '//localhost:8080',
    client: '//localhost:3000'
  }
}

const getEnv = () => {
  const { host } = window.location
  return host.endsWith('.io') ? 'test' : (host.endsWith('.com') || host.endsWith('.net')) ? 'production' : 'development'
}

const env = getEnv()
console.info('application env', env)

export default environments[env]