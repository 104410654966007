import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { MD5 } from 'crypto-js'
import Axios from 'axios'
import logo from '../../assets/logo@2x.webp'
import './Navbar.sass'

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      user: {},
      error: null
    }
  }

  async componentDidMount() {
    try {
      const { data: user } = await Axios.get('/admins/current')
      this.setState({ user })
    } catch (error) {
      this.setState({ error })
    }
  }

  toggle() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  gravatar(email) {
    const hash = MD5(email).toString()
    const url = `https://www.gravatar.com/avatar/${hash}?d=mp&s=36`
    return url
  }

  render() {
    const { isOpen, user } = this.state

    return (
      <div className="Navbar">
        <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/panel/dashboard">
              <img src={logo} alt="Carteira Digital" />&nbsp;&nbsp;
              <span className="title is-size-6">Carteira Digital</span>
            </Link>

            <span role="button" className={`navbar-burger burger ${isOpen && 'is-active'}`} aria-label="menu" aria-expanded="false" onClick={this.toggle.bind(this)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>

          <div className={`navbar-menu ${isOpen && 'is-active'}`} onClick={this.toggle.bind(this)}>
            <div className="navbar-start">
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/panel/dashboard">Dashboard</NavLink>
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/panel/user-admin">Administradores</NavLink>
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/panel/user">Usuários</NavLink>
              <NavLink className="navbar-item" activeClassName="has-background-light" to="/panel/withdrawal">Saques</NavLink>
            </div>

            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable current-user">
                <span className="navbar-link">
                  <figure className="image">
                    <img className="is-rounded" src={this.gravatar(user.Email)} alt={user.Name} />
                  </figure>
                  <span>{user.Name}</span>
                </span>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/login">Sair</Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}
